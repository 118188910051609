import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Box, Grid, Heading, Text, Link, Image } from "theme-ui";
import mixpanel from "mixpanel-browser";

import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { OpeningHours } from "../components/OpeningHours";
import { Address } from "../components/Address";
import { Gallery } from "../components/Gallery";
import InstagramIcon from "../images/instagram-icon.svg";
import { trackEvent } from "../utils/trackEvent";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      galleryImages: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1024)
            }
          }
        }
      }
    }
  `);
  const galleryImages = data.galleryImages.edges.map(
    ({ node }) => node.childImageSharp
  );

  useEffect(() => {
    mixpanel.init("453f02620fdc852605bc67753d6f3151", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <html lang="cs" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Penzion a Stripbar 69 v Ostravě-Třebovicích. Každý večer se na vás těší spousta milých hostesek,
                striptýz, tanečnice a příjemná obsluha!"
        />
        <meta name="format-detection" content="telephone=no" />
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>

      <Section variant="sectionDancer">
        <Header title={data.site.siteMetadata.title} />
        <Container
          sx={{
            position: "relative",
            maxWidth: "100%",
            p: 0,
            textAlign: "center",
            "&:before, &:after": {
              content: '""',
              position: "absolute",
              left: 0,
              right: 0,
              width: "100%",
              height: "23%",
              zIndex: 1,
            },
            "&:before": {
              top: 0,
              background: "linear-gradient(to top, #1C1B2200, #1C1B22)",
              opacity: 0,
            },
            "&:after": {
              bottom: 0,
              background: "linear-gradient(to bottom, #1C1B2200, #1C1B22)",
            },
          }}
        >
          <StaticImage
            src="../images/dancer-2.jpg"
            width={1920}
            height={640}
            objectPosition="50% 50%"
            transformOptions={{ cropFocus: "center" }}
            quality={90}
            alt=""
            placeholder="none"
            backgroundColor="#1C1B22"
          />
        </Container>
      </Section>

      <Section variant="sectionHero">
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "auto auto", "1fr auto 1fr"],
              gridTemplateRows: ["auto auto auto", "auto auto", "auto"],
              gap: [4, 4, 5],
              mb: 5,
            }}
          >
            <Box>
              <Heading as="h2">
                Strip Bar Ostrava-Třebovice
              </Heading>

              <Text as="p" variant="introText" sx={{ mb: 3, fontSize: 1 }}>
                Pension&StripBar69 is the largest nightclub in Ostrava with a
                great atmosphere, lots of dancers, and nearly ten rooms for
                private striptease shows.
              </Text>

              <Link
                variant="iconLink"
                href="https://www.instagram.com/penzion.stripbar69/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackEvent("instagram")}
              >
                <Image src={InstagramIcon} alt="Instagram" aria-hidden />
                Instagram
              </Link>
            </Box>

            <Box>
              <Heading as="h2">Opening hours</Heading>

              <OpeningHours />
            </Box>

            <Grid
              sx={{
                gap: 0,
                alignContent: "start",
                justifyItems: ["stretch", "start"],
              }}
            >
              <Heading as="h2">Location</Heading>

              <Box sx={{ mb: 4 }}>
                <Address />
              </Box>

              <Link
                variant="primary"
                href="https://goo.gl/maps/tE4o2wZdLNbp2si79"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackEvent("navigate")}
              >
                Navigate
              </Link>
            </Grid>
          </Grid>

          <Box as="p" variant="accepting" sx={{ mb: 5, p: 4 }}>
            Are you looking for a flexible, fun, and well-paid job? Send an
            email with a brief description and an attached photo to{" "}
            <Link href="mailto:penzion69@gmail.com">penzion69@gmail.com</Link>.
            We are looking for GoGo, pole dance, strip, and topless cheerful
            dancers over 18 years old.
          </Box>
        </Container>

        <Container p={0}>
          <Gallery images={galleryImages} />
        </Container>
      </Section>

      <Footer title={data.site.siteMetadata.title} />
    </Layout>
  );
};

export default IndexPage;
